import './App.css';
import { useEffect, useRef, useState } from 'react';
import fetchTime from './fetchTime';
// import { URLAnimation, Animations } from 'url-animations';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import 'react-tooltip/dist/react-tooltip.css';

function App() {
  const [isCopied, setIsCopied] = useState(false);
  const [discordIsLoading, setDiscordIsLoading] = useState(true);
  const [time, setTime] = useState(fetchTime());
  // const URL = URLAnimation();

  useEffect(() => {
    // URL.start(Animations.plane);
    setTimeout(() => {
      setDiscordIsLoading(false);
    }, 1500);
    setInterval(() => setTime(fetchTime()), 1000);
    document.title = 'Hi';
  }, []);

  const copyDiscordTag = () => {
    setIsCopied(true);
    navigator.clipboard.writeText('tash#0001');
    setTimeout(() => {
      setIsCopied(false);
    }, 2000);
  };

  return (
    <div className='App'>
      <header className='App-header'>
        <span className='top-left'>
          <p>My local time is: {time}</p>
          <p>Guess where I'm from</p>
        </span>
        <p>Hi. I am a web developer and C# developer</p>
        <p>I like VRChat</p>
        <div
          className='SkeletonLoader--wrapper'
          id='my-element'
          // data-tooltip-content='Go to my profile'
        >
          {discordIsLoading ? <div className='SkeletonLoader' /> : null}
          <a
            target={'_blank'}
            rel='noreferrer'
            href='https://discord.com/users/280603870755946496'
          >
            <img
              alt='discord pfp'
              src='https://discord.c99.nl/widget/theme-4/280603870755946496.png'
            />
          </a>
        </div>
        <ReactTooltip
          anchorId='my-element'
          content={'Go to my profile'}
          delayShow={100}
          float={true}
          style={{ fontSize: '1.3rem' }}
        />
        <button
          className='copyBtn'
          onClick={copyDiscordTag}
          disabled={isCopied}
        >
          {isCopied ? 'Copied' : 'Click here to copy my discord tag'}
        </button>
      </header>
      <img src='http://gg.gg/13gmxf' />
    </div>
  );
}

export default App;
