const fetchTime = () => {
  // create Date object for current location
  const date = new Date();

  // convert to milliseconds, add local time zone offset and get UTC time in milliseconds
  const utcTime = date.getTime() + date.getTimezoneOffset() * 60000;

  // time offset for Antarctica is +10
  const timeOffset = 10;

  // create new Date object for a different timezone using supplied its GMT offset.
  const myDate = new Date(utcTime + 3600000 * timeOffset);

  const myTime = myDate.toLocaleString('en-US', {
    hour: 'numeric',
    minute: 'numeric',
    second: 'numeric',
    hour12: true,
  });

  return myTime;
};

export default fetchTime;
